import { PageType } from "@product/scmp-sdk";
import { useRouter } from "next/router";
import { type FunctionComponent } from "react";

import { useAppBarSetStickyScrollHeight } from "scmp-app/components/app-bar/hooks/sticky/app-bar-set-sticky-scroll-height";
import { ClientSideSuspense } from "scmp-app/components/common/client-side-suspense";
import { DefaultHeaderSwapThreshold } from "scmp-app/components/header/consts";
import { HeaderLogo } from "scmp-app/components/section/section-style/styles";
import { section as sectionData } from "scmp-app/data";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";

import { useHeader, useSwapOnScrollEffect } from "./hooks";
import {
  Container,
  HeaderContainer,
  LeftFeatureContainer,
  RightFeatureContainer,
  StyleHeaderLogoContainer,
} from "./styles";
import type { HeaderConfiguration } from "./types";

export type Props = {
  className?: string;
  headerConfiguration?: HeaderConfiguration;
};

export const Header: FunctionComponent<Props> = ({ className, headerConfiguration }) => {
  const { query } = useRouter();
  const currentPageType = useCurrentPageType();
  const { reference } = useAppBarSetStickyScrollHeight();

  const isMagazineStyle = headerConfiguration?.appBarVariant === "scmp/magazines-style";
  const isSectionStyleMainPage =
    currentPageType === PageType.Section && query.entityId === sectionData.style.entityId;

  const { shouldSwap } = useSwapOnScrollEffect(
    (headerConfiguration?.appBarStickyScrollHeight ?? 0) || DefaultHeaderSwapThreshold,
  );
  const { features } = useHeader(shouldSwap, headerConfiguration);

  return (
    <Container $isMagazineStyle={isMagazineStyle} className={className}>
      {isSectionStyleMainPage && (
        <StyleHeaderLogoContainer ref={reference}>
          <HeaderLogo />
        </StyleHeaderLogoContainer>
      )}
      <HeaderContainer>
        <ClientSideSuspense>
          <LeftFeatureContainer>{features.left}</LeftFeatureContainer>

          {features.center}

          <RightFeatureContainer>{features.right}</RightFeatureContainer>
        </ClientSideSuspense>
      </HeaderContainer>
    </Container>
  );
};

Header.displayName = "Header";
