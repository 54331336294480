import { notEmpty } from "@product/scmp-sdk";
import { useResizeObserver } from "@react-hookz/web";
import { useSetAtom } from "jotai";
import { useCallback, useState } from "react";

import { appBarScrollStickyAtom } from "scmp-app/components/app-bar/hooks/sticky/atoms";

export const useAppBarSetStickyScrollHeight = <T extends HTMLElement>() => {
  const setAppBarScrollSticky = useSetAtom(appBarScrollStickyAtom);
  const [element, setElement] = useState<null | T>(null);

  const handleResize = useCallback(() => {
    if (!element) {
      setAppBarScrollSticky({ stickyScrollHeight: 0 });
      return;
    }

    const rect = element.getBoundingClientRect();
    setAppBarScrollSticky({ stickyScrollHeight: rect.height ?? 0 });
  }, [element, setAppBarScrollSticky]);

  const reference = useCallback((node: null | T) => {
    if (!notEmpty(node)) return;
    setElement(node);
  }, []);

  useResizeObserver(element, _ => {
    handleResize();
  });

  return {
    reference,
  };
};
